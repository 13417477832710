import React, {useContext, useState} from 'react';
import {observer} from "mobx-react";
import {Shortcut} from "../../../header/shortcuts/Shortcut";
import {StoreContext} from "../../../../stores/StoreLoader";
import {conditionType, currentViewType} from "../../../modals/AdminModal/AdminModalTypes";
import WatsonApi from "../../../../backends/WatsonApi";
import NotificationManager from "../../../notifications/NotificationManager";
import styles from "./styles/ShortcutsTerrace.module.scss"
import classNames from 'classnames';
import NavigationModel from "../../../../utils/NavigationModel";
import FontAwesome from "../../../utilities/FontAwesome";
import {addLeadingZeros} from "../../../../utils/DataUtilities";
import dynamic from "next/dynamic";
import BlockTitle from "../_BlockTitle";
import {EditDropDownItem} from "../EditDropDown";

const DraggableShortCut = dynamic(() => import("../../../header/shortcuts/DraggableShortcut"));

const ShortcutsTerrace = observer((props: {
    blockObj: IShortcutsTerrace,
    aboveTheFold: boolean,
}) => {
    const {userStore, organizationStore, modalStore, interfaceStore} = useContext(StoreContext);
    const [isDragging, setIsDragging] = useState(false);
    const [reorder, setReorder] = useState(false);
    const [canEdit, setCanEdit] = useState(false);

    const shortcutsTerraceContainerClassName = classNames({
        [styles.container]: true,
        [styles.containerIsEditor]: userStore.isEditor,
        [styles.containerGreaterThanSix]: props.blockObj?.shortcuts?.length > 6,
        [styles.containerBackgroundImage]: !!organizationStore.currentOrganization.backgroundImage,
    })

    const reorderClassName = classNames({
        [styles.reorder]: reorder,
    });

    const navigationUpdater = async (navigation) => {
        try {
            const client = await WatsonApi();
            const response = await client.apis.organizations.organizations_terraces_navigation_partial_update({
                organization_pk: props.blockObj.organization_id,
                terrace_pk: props.blockObj.id,
                id: navigation.id,
                data: navigation,
            })

            props.blockObj.shortcuts = props.blockObj.shortcuts.map(link => {
                if (link.id === navigation.id) {
                    return JSON.parse(response.data);
                }
                return link;
            })
        } catch (e) {
            NotificationManager.error("Something went wrong! Please try again.")
        }
    }

    const updateNavigation = (navigation) => {
        modalStore.addModal({
            type: "admin",
            condition: conditionType.EDITING,
            nodeInfo: navigation,
            navigation: [],
            parent: String(organizationStore.currentOrganization.id),
            navigationUpdater,
        })
    }

    const deleteNavigation = async (id) => {
        if (confirm("Are you sure you want to do that?")) {
            try {
                const client = await WatsonApi();
                await client.apis.organizations.organizations_terraces_navigation_bulk_destroy({
                    organization_pk: props.blockObj.organization_id,
                    terrace_pk: props.blockObj.id,
                    data: [id],
                });
                props.blockObj.shortcuts = props.blockObj.shortcuts.filter(n => n.id !== id);
            } catch (e) {
                NotificationManager.error("Something went wrong! Please try again.")
            }
        }
    }

    function _setEditing(value, cancel) {
        setCanEdit(value);
        setReorder(false);
    }

    const navigationCreator = async (navigation) => {
        try {
            const index = props.blockObj.shortcuts.length;

            const client = await WatsonApi();
            const response = await client.apis.organizations.organizations_terraces_navigation_create({
                organization_pk: props.blockObj.organization_id,
                terrace_pk: props.blockObj.id,
                data: {
                    title: navigation.title,
                    type: 0,
                    external_url: navigation.external_url,
                    icon: navigation.icon,
                    class_field: navigation.class_field,
                    description: navigation.description,
                    path: `${props.blockObj.id}.${addLeadingZeros(index + 1)}`,
                    enabled: navigation.enabled,
                    terrace: props.blockObj.id,
                    organization_id: navigation.organization_id,
                },
            });

            const newNav = JSON.parse(response.data);
            props.blockObj.shortcuts = [...props.blockObj.shortcuts, newNav];
            return newNav;
        } catch (e) {
            NotificationManager.error("Something went wrong! Please try again.")
        }
    }

    function onHoverCallback(list) {
        props.blockObj.shortcuts = list
    }

    async function onDrop() {
        for (let i=0; i<props.blockObj.shortcuts.length; i++) {
            const newPath = `${props.blockObj.id}.${addLeadingZeros(i + 1)}`
            props.blockObj.shortcuts[i].path = newPath;
        }
        try {
            const client = await WatsonApi();
            await client.apis.organizations.organizations_terraces_navigation_bulk_update({
                organization_pk: organizationStore.currentOrganization.id,
                terrace_pk: props.blockObj.id,
                data: props.blockObj.shortcuts,
            });
            NotificationManager.success("Shortcuts successfully updated.");
        } catch (e) {
            NotificationManager.error("Oops! Something went wrong. Please try again.");
        }
    }

    const addShortcut = () => {
        modalStore.addModal({
            type: "admin",
            condition: conditionType.ADD_TOPLINK,
            nodeInfo: new NavigationModel(),
            navigation: [],
            parent: String(organizationStore.currentOrganization.id,),
            initialView:currentViewType.shortcut,
            navigationCreator,
        });
    }

    const reorderShortcutsDropDownItem = {
        title: 'Toggle Reordering',
        icon: <FontAwesome prefix={"fas"} fixedWidth name={'fa-arrows-left-right'} ariaHidden className={reorderClassName}/>,
        onClick: () => {
            setReorder(v => !v);
            setCanEdit(false);
        },
        hideButtonSelector: true,
    };

    const editShortcutsDropDownItem = {
        title: canEdit ? 'Close Editor' : 'Edit',
        icon: <FontAwesome prefix={'fas'} fixedWidth className="fa-pencil-alt"/>,
        onClick: () => {
            setCanEdit(v => !v);
            setReorder(false);
        },
    };

    const shortcutsTerraceDropDownItems: EditDropDownItem[] = [
        {
            title: 'Add Shortcut',
            icon: <FontAwesome prefix={'fas'} fixedWidth name={"fa-plus"}/>,
            onClick: addShortcut,
        },
    ];

    if (props.blockObj?.shortcuts?.length > 0) {
        shortcutsTerraceDropDownItems.push(editShortcutsDropDownItem);
    }

    if (props.blockObj?.shortcuts?.length > 1) {
        shortcutsTerraceDropDownItems.push(reorderShortcutsDropDownItem);
    }

    return (
        <article>
            <BlockTitle
                blockObj={props.blockObj}
                aboveTheFold={props.aboveTheFold}
                editing={canEdit}
                setEditing={_setEditing}
                allowSave={true}
                dropDownItems={shortcutsTerraceDropDownItems}
                customTitleComponent={userStore.isEditor ? undefined : <></>}
            />
            <div className={shortcutsTerraceContainerClassName}>
                {props.blockObj?.shortcuts?.length > 0 && <ul>
                    {props.blockObj.shortcuts.map((link, index) => {
                        const iconClassName = classNames({
                            [styles.icon]: true,
                            [styles.iconReorder]: reorder,
                            [styles.iconEditing]: canEdit,
                        });

                        if (userStore.isEditor) {
                            return <div className={iconClassName} title={reorder ? "Reorder Shortcut" : undefined}>
                                <DraggableShortCut key={link.id}
                                                   shortcut={link}
                                                   subNavigation={props.blockObj.shortcuts}
                                                   onHoverCallback={onHoverCallback}
                                                   index={index}
                                                   isDragging={isDragging}
                                                   setIsDragging={setIsDragging}
                                                   reorder={reorder}
                                                   updateNavigation={updateNavigation}
                                                   deleteNavigation={deleteNavigation}
                                                   canEdit={canEdit}
                                                   onDrop={onDrop}
                                />
                            </div>
                        } else {
                            return <div className={iconClassName}>
                                <Shortcut key={link.id}
                                          shortcut={link}
                                          updateNavigation={updateNavigation}
                                          deleteNavigation={deleteNavigation}
                                          canEdit={canEdit}/>
                            </div>
                        }
                    })}
                </ul>}
            </div>
        </article>
    );
})

export default ShortcutsTerrace;
